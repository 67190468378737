/*body {*/
/*    font-family: "Open Sans", sans-serif;*/
/*    font-size: 16px;*/
/*    -webkit-font-smoothing: antialiased;*/
/*    text-rendering: optimizelegibility;*/
/*    color: #223254;*/
/*    min-height: 100vh;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/
/*body {*/
/*    min-height: 100vh;*/
/*    font-family: "Inter", Arial, sans-serif;*/
/*    color: #8A91B4;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    background: #F6F8FF;*/
/*}*/
.configTestDiv {
    display: table;
}

.btnDifIn {
    box-shadow: inset -7em -1em 3px #68b43c
}

.startTest {
    background-color: #4caf50;
    border: solid 1px;
    border-radius: 5px;
    color: white;
    padding: 9px 26px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
}

.startTest:hover {
    background-color: #282c34; /* Green */
    color: white;
}

.configDescTest {
    display: table-cell;
    width: 700px;
    height: 300px;
    justify-content: center;
    line-height: 30px;
    padding: 25px;
}

.configSubOptionTest {
    display: table-cell;
    width: 500px;
    height: 300px;
    text-align: left;
}

.alarmConfig {
    color: red;
    display: none;
}

/* CONTAINERS diff RADIO SECTION */

.configDiffTest {
    max-width: 850px;
    min-width: 500px;
    height: 300px;

}

.four {
    width: 32.26%;
    max-width: 32.26%;
}

/* COLUMNS */

.col {
    display: block;
    float: left;
    margin: 6% 0 1% 1.6%;
}

.col:first-of-type {
    margin-left: 0;
}

/* CLEARFIX */

.cf:before, .cf:after {
    content: " ";
    display: table;
}

.cf:after {
    clear: both;
}

/* FORM */

.form .plan input,
.form .payment-plan input,
.form .payment-type input {
    display: none;
}

.form label {
    position: relative;
    color: #fff;
    background-color: #aaa;
    font-size: 26px;
    text-align: center;
    height: 150px;
    line-height: 150px;
    display: block;
    cursor: pointer;
    border: 3px solid transparent;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.form .plan input:checked + label,
.form .payment-plan input:checked + label,
.form .payment-type input:checked + label {
    border: 3px solid #333;
    background-color: #2fcc71;
}

.form .plan input:checked + label:after,
form .payment-plan input:checked + label:after,
.form .payment-type input:checked + label:after {
    content: "\2713";
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 100%;
    border: 2px solid #333333;
    background-color: #2fcc71;
    z-index: 999;
    position: absolute;
    top: -10px;
    right: -10px;
}


@supports (-webkit-appearance: none) or (-moz-appearance: none) {
    .configSubOptionTest input[type=checkbox],
    .configSubOptionTest input[type=radio] {
        --active: #275EFE;
        --active-inner: #fff;
        --focus: 2px rgba(39, 94, 254, .3);
        --border: #BBC1E1;
        --border-hover: #275EFE;
        --background: #fff;
        --disabled: #F6F8FF;
        --disabled-inner: #E1E6F9;
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 21px;
        outline: none;
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin: 0;
        cursor: pointer;
        border: 1px solid var(--bc, var(--border));
        background: var(--b, var(--background));
        transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    }

    .configSubOptionTest input[type=checkbox]:after,
    .configSubOptionTest input[type=radio]:after {
        content: "";
        display: inline-block;
        left: 0;
        top: 0;
        position: absolute;
        transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
    }

    .configSubOptionTest input[type=checkbox]:checked,
    .configSubOptionTest input[type=radio]:checked {
        --b: var(--active);
        --bc: var(--active);
        --d-o: .3s;
        --d-t: .6s;
        --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
    }

    .configSubOptionTest input[type=checkbox]:disabled,
    .configSubOptionTest input[type=radio]:disabled {
        --b: var(--disabled);
        cursor: not-allowed;
        opacity: 0.9;
    }

    .configSubOptionTest input[type=checkbox]:disabled:checked,
    .configSubOptionTest input[type=radio]:disabled:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
    }

    .configSubOptionTest input[type=checkbox]:disabled + label,
    .configSubOptionTest input[type=radio]:disabled + label {
        cursor: not-allowed;
    }

    .configSubOptionTest input[type=checkbox]:hover:not(:checked):not(:disabled),
    .configSubOptionTest input[type=radio]:hover:not(:checked):not(:disabled) {
        --bc: var(--border-hover);
    }

    .configSubOptionTest input[type=checkbox]:focus,
    .configSubOptionTest input[type=radio]:focus {
        box-shadow: 0 0 0 var(--focus);
    }

    .configSubOptionTest input[type=checkbox]:not(.switch),
    .configSubOptionTest input[type=radio]:not(.switch) {
        width: 21px;
    }

    .configSubOptionTest input[type=checkbox]:not(.switch):after,
    .configSubOptionTest input[type=radio]:not(.switch):after {
        opacity: var(--o, 0);
    }

    .configSubOptionTest input[type=checkbox]:not(.switch):checked,
    .configSubOptionTest input[type=radio]:not(.switch):checked {
        --o: 1;
    }

    .configSubOptionTest input[type=checkbox] + label,
    .configSubOptionTest input[type=radio] + label {
        font-size: 14px;
        line-height: 21px;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        margin-left: 14px;
    }

    .configSubOptionTest input[type=checkbox]:not(.switch) {
        border-radius: 7px;
    }

    .configSubOptionTest input[type=checkbox]:not(.switch):after {
        width: 5px;
        height: 9px;
        border: 2px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 7px;
        top: 4px;
        transform: rotate(var(--r, 20deg));
    }

    .configSubOptionTest input[type=checkbox]:not(.switch):checked {
        --r: 43deg;
    }

    .configSubOptionTest input[type=checkbox].switch {
        width: 38px;
        border-radius: 11px;
    }

    .configSubOptionTest input[type=checkbox].switch:after {
        left: 2px;
        top: 2px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        background: var(--ab, var(--border));
        transform: translateX(var(--x, 0));
    }

    .configSubOptionTest input[type=checkbox].switch:checked {
        --ab: var(--active-inner);
        --x: 17px;
    }

    .configSubOptionTest input[type=checkbox].switch:disabled:not(:checked):after {
        opacity: 0.6;
    }

    .configSubOptionTest input[type=radio] {
        border-radius: 50%;
    }

    .configSubOptionTest input[type=radio]:after {
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background: var(--active-inner);
        opacity: 0;
        transform: scale(var(--s, 0.7));
    }

    .configSubOptionTest input[type=radio]:checked {
        --s: .5;
    }
}

.formCheckbox {
    margin-top: 15px;
}

/**
 *  test style
 */

.testDiv {
    text-align: center;
    min-height: 750px;
    margin-top: 15px;
}


ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.carousel {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 950px;
    margin-top: 40px;
}

.carousel__slide {
    display: none;
    max-width: 900px;
    list-style-type: none;
    text-align: center;
    min-height: 350px;
    margin-top: 40px;
}

.carousel__slide--active {
    display: inline-block;
}

.carousel__slide_cur_answer {
    display: none;
    max-width: 900px;
    list-style-type: none;
    text-align: center;
    min-height: 100px;
    margin-top: 40px;
}

.carousel__slide_cur_answer--active {
    display: inline-block;
}

.carousel__slide_answers {
    display: none;
    max-width: 900px;
    list-style-type: none;
    text-align: center;
    min-height: 200px;
    margin-top: 40px;
}

.carousel__slide_answers--active {
    display: inline-block;
}

.carousel-slide__content {
    font-family: "Open Sans", "Trebuchet MS", sans-serif;
    font-size: 16px;
    word-break: break-all;
    color: black;
    height: 360px;
    width: 600px;
    text-align: justify;
    display: inline-block;
    margin-top: 30px;
    background-color: #f5f2f0;
    border-radius: 3px;
    position: relative;
}

.p-number-question{
    position: absolute;
    right: 10px;
    bottom: 0;
    color: #c1c1c1;
}

.carousel-slide__author,
.carousel-slide__source {
    font-family: "Roboto", arial, sans-serif;
    font-size: 14px;
}

@media (min-width: 992px) {
    .carousel-slide__author,
    .carousel-slide__source {
        font-size: 16px;
    }
}

.carousel-slide__source {
    font-style: italic;
    color: #888;
}

.carousel__arrow {
    position: absolute;
    top: 50%;
    display: block;
    color: #111;
    cursor: pointer;
    opacity: 0.75;
    transform: translateY(-50%);
    transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1);

}

.carousel__arrow:focus {
    outline: 0;
}

.carousel__arrow:hover {
    opacity: 0.5;
}

.carousel__arrow:hover[disabled=true] {
    cursor: not-allowed;
}

.carousel__arrow--left {
    left: 32px;
}

.carousel__arrow--right {
    right: 32px;
}

.carousel__indicators {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
}

.carousel__indicators li:nth-of-type(n + 2) {
    margin-left: 9px;
}

.carousel__indicator {
    display: block;
    width: 24px;
    height: 3px;
    background-color: #111;
    cursor: pointer;
    opacity: 0.15;
    transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1);
}

.carousel__indicator:hover {
    opacity: 0.5;
}

.carousel__indicator--active, .carousel__indicator--active:hover {
    opacity: 0.75;
}

.btnFinal {
    margin-top: 60px;
    background-color: #4caf50;
    border: solid 1px;
    border-radius: 5px;
    color: white;
    padding: 7px 20px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.7s;
    cursor: pointer;
}

.btnFinal:hover {
    background-color: #282c34;
    color: white;
    cursor: pointer;
}

.carousel-result {
    min-height: 300px;
    text-align: center;
}

.btnOpenAnswers {
    background-color: #4caf50;
    border: solid 1px;
    border-radius: 5px;
    color: white;
    padding: 7px 20px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.7s;
    cursor: pointer;
}

.btnOpenAnswers:hover {
    background-color: #282c34;
    color: white;
    cursor: pointer;
}

.divResult {
    justify-content: center;
    margin-top: 40px;
    line-height: 40px;
}

.btnNewTest {

    background-color: #127a9e;
    border: solid 1px;
    border-radius: 5px;
    color: white;
    padding: 7px 20px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.7s;
    cursor: pointer;
}

.btnNewTest:hover {
    background-color: #282c34;
    color: white;
    cursor: pointer;
}

.timer {
    color: #f00;
    font-size: 150%;
    font-weight: bold;
    margin-top: 30px;
}

.formBtnShare{
    padding-bottom: 25px;
}

.inputAnswer{
 margin-right: 15px;
}
.labelAnswer{
    word-break: break-word;
    word-wrap: break-spaces;
}

