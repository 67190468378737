.divNavbar {
    text-align:center;
    width:90%;
   /* border:2px solid floralwhite;*/
   /* background: #eee5e5;*/
   /* box-shadow:0 0 10pt #c4c3c3;*/
    padding:10pt;
}
.logoBtnNavbar{
    display: inline-block;


}
.logoBtnNavbar:hover{
    box-shadow:0 5px 0 #c4c3c3;
}

[id^=radio-] {
    display: none;
}

[id^=radio-] + label {
    display: inline-block;
    vertical-align: middle;
    font-size: 50px;
    color: #555;
    cursor: pointer;
}

[id^=radio-]:checked + label {
    height: 20px;
    background-color: #ffffff;
    cursor: text;
}

.logInTrue{
    display: flex;
}

.formBtnShare{
    display: block;
    margin-top: 50px;
}

.vk_share_button{
    background-color: white;
    border: none;
}

.div-stub-root{
    background-color: #282c34;
    width: 100%;
    height: 100vh;
    position: absolute;
}

.div-stub-child{
    width: 570px;
    height: 110px;
    position: absolute;
    top: 50%;
    left: 28%;
    margin: -3vh 0 0 -5vh;
    border: #4caf50 solid 3px;
    border-radius: 5px;
}

.p-stub{
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    border: none;
    color: #ffffff;
    font-size: 230%;
    padding: 5px 5px 5px 17px;
}