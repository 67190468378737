
* {
    margin: 0 auto;
}

.divHeader {
    height: 19vh;
    text-align: center;
}

.divNav {
    height: auto;
    text-align: center;
}

.navBarAside {
    min-height: 250px;
}

.divMain {
    min-height: 400px;
    text-align: center;
}

.divLogin {
    height: 5vh;
    width: 15vw;
    margin-right: 2%;
    text-align: center;
}

.question {
    width: 60vw;
}

.divQuestions {
    height: auto;
    text-align: center;
}

.divAnswer {
    display: flex;
    float: bottom;
    margin-left: -0.25em;
}

.labelAnswer {
    border-radius: 7px;
    color: black;
    height: 70px;
    width: 750px;
    padding: 1vh 1vw;
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: left;
    font-size: 16px;
    display: inline-block;
    word-break:break-all;
    background-color: #d6dfe8;
}

.divAnswerFull {
    background-color: gainsboro;
    border-radius: 7px;
    word-break: break-all;
    color: black;
    width: 860px;
    padding: 1vh 1vw;
    text-align: left;
    font-size: 16px;
}

.imgHead {
    margin-top: 3%;
    min-height: 7vh;
    min-width: 7vh;
}

.butLogin {
    background-color: #4caf50;
    border: solid 1px;
    border-radius: 5px;
    color: white;
    padding: 7px 20px;
    margin-right: 5%;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
}

.butLogin:hover {
    background-color: #282c34;
    color: white;
}


.App-header {
    background-color: #282c34;
    /*min-height: 100vh;*/
    /*display: flex;*/
    /*flex-direction: column;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    /*font-size: calc(10px + 2vmin);*/
    color: white;
}

#gallery .question .divQuestions {
    display: none;
}

#gallery .question .divQuestions:first-child {
    display: block;
}

#gallery {
    width: 66.5vw;
}

.buttons {
    display: flex;
}

.buttons button {
    margin-top: 20px;
    background-color: white;
    opacity: .3;
    border: none;
    outline: none;
}

.buttons button:hover {
    margin-top: 20px;
    background-color: #dfe2e2;
    opacity: .6;
    border: none;
    border-radius: 5px;
    outline: none;
}

.divButtonsCurrent {
    height: 0.4vh;

}

.buttonNumber {
    background-color: #d3d5d9;
    border-radius: 10px;
    width: 2.1vw;
    outline: none;
    border: none;
}
