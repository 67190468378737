.divFoot {
    background-color: #282c34;
    text-align: center;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 130px;
}

.p-author {
    margin-top: auto;
    color: #5e5e6d;
    padding-top: 55px;
}